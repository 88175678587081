
// need to load neccecary
import './modules/necessary';

// may loads deferred
import './modules/rotators';
import './modules/tabs';
import './modules/modals';
import './modules/forms';
import './modules/spinner';
import './modules/masks';
import './modules/scroll';
import './modules/menus';
import './modules/filters';
import './modules/catalogue';
import './modules/card';
import './modules/inview';
import './modules/order';
import './modules/misc';

// Lazy load
// ---------

$(() => {
  $(window).on('yiiListViewUpdated iasRender tabsInit tabChanged unveilInit instagramPageInited', function() {
    setTimeout(() => { $('.js-lazy-pic').unveil(200); }, 100);
  }).trigger('unveilInit');
});


$(function() {
  objectFitImages(); // Инициализация полифилла для css object-fit
});
